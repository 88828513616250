<!-- 发展历程 -->
<template>
  <div>
    <div class="header">
      <div>
        <h3>{{ text1 }}</h3>
      </div>
    </div>

    <div class="container">
      <img class="content" :src="img1" alt="" />
    </div>
  </div>
</template>

<script>
let language = localStorage.getItem("language") || "cn";
import Swiper from "swiper";
import { mapState } from "vuex";
export default {
  data() {
    switch (language) {
      case "cn":
        return {
          text1: "发展历程",
          img1: "https://jw-ppt.oss-cn-beijing.aliyuncs.com/zl/project/FutureGens/src/content.png",
        };
        break;
      case "en":
        return {
          text1: "Corporate History",
          img1: "https://jw-ppt.oss-cn-beijing.aliyuncs.com/zl/project/FutureGens/src/content_en.png",
        };
        break;
    }
  },
  computed: {
    ...mapState(["isLanguage"]),
  },
  mounted() {
    new Swiper(".mySwiper", {
      slidesPerView: 1.2,
      spaceBetween: 30,
      centeredSlides: true,
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
    });
  },
};
</script>

<style lang="less" scoped>
body {
  background: #eee;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 14px;
  color: #000;
  margin: 0;
  padding: 0;
}
.header {
  width: 100%;
  height: 400px;
  background: url("https://jw-ppt.oss-cn-beijing.aliyuncs.com/zl/project/FutureGens/img/guanyu/fezhan/header.png") no-repeat;
  background-size: 100% 100%;
  div {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    h3 {
      position: relative;
      font-size: 50px;
      color: #fff;
      padding-top: 20%;
    }
    h3::after {
      position: absolute;
      top: 110%;
      left: 0%;
      content: "";
      width: 50px;
      height: 2px;
      background-color: #fff;
    }
  }
}
.container {
  margin: 150px;
  display: flex;
  justify-content: center;
  img {
    width: 100%;
    height: 100%;
  }
}

@media screen and (max-width: 750px) {
  .header {
    height: 150px;
    div {
      h3 {
        font-size: 24px;
        margin-left: 10%;
        font-weight: normal;
        padding-top: 20%;
      }
      h3::after {
        width: 30px;
      }
    }
  }
  .container {
    width: 100vw;
    margin: 70px 0px;
    box-sizing: content-box;
    img {
      width: 95vw;
    }
    .left {
      display: none;
    }
    .right {
      display: none;
    }
  }
}
</style>
